/* src/styles/main.css v1.2 */

/* Import normalize.css */
@import 'normalize.css';

/* CSS Variables for Theming */
:root {
  /* Light Theme Colors (Default) */
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --text-color: #212529;
  --footer-bg: #343a40;
  --footer-text: rgba(255, 255, 255, 0.8);
  --hover-color: #0056b3;
  
  /* Shadows */
  --shadow-sm: 0 2px 5px rgba(0,0,0,0.1);
  --shadow-md: 0 2px 10px rgba(0,0,0,0.1);
  
  /* Spacing */
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 2rem;
  --spacing-lg: 3rem;
  
  /* Transitions */
  --transition-default: all 0.3s ease;
  
  /* Border Radius */
  --border-radius-sm: 5px;
  --border-radius-md: 10px;
}

/* Dark Theme Colors */
[data-theme="dark"] {
  --primary-color: #4dabf7;
  --secondary-color: #868e96;
  --background-color: #1a1a1a;
  --text-color: #f8f9fa;
  --footer-bg: #2d2d2d;
  --footer-text: rgba(255, 255, 255, 0.9);
  --hover-color: #74c0fc;
}

/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: var(--transition-default);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  margin-bottom: var(--spacing-sm);
  line-height: 1.2;
}

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition-default);
}

a:hover,
a:focus {
  color: var(--hover-color);
  text-decoration: underline;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  display: block;
  loading: lazy;
}

/* Card Styles */
.gadget-card {
  transition: var(--transition-default);
  border: none;
  box-shadow: var(--shadow-sm);
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm);
}

.gadget-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

/* Memory Game Styles */
.memory-game-card {
  cursor: pointer;
  height: 150px;
  perspective: 1000px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm);
}

/* Contact Form Styles */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: var(--spacing-md);
  background: var(--background-color);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
}

/* Logo Styles */
.logo-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
  transition: var(--transition-default);
  background-color: var(--background-color);
  position: relative;
  z-index: 1;
  min-height: 25vh; /* Reduced from 50vh to 25vh */
  align-items: center;
}

.eg-logo {
  max-width: 25%; /* Reduced from 50% to 25% */
  height: auto;
  opacity: 1;
  transform: scale(1);
  transition: var(--transition-default);
}

@media (max-width: 768px) {
  .eg-logo {
    max-width: 35%; /* Reduced from 70% to 35% */
  }
  
  .logo-container {
    padding: 1.5rem;
    min-height: 20vh; /* Reduced from 40vh to 20vh */
  }
}

/* Hero Section Styles */
.hero-section {
  background: linear-gradient(135deg, var(--primary-color), var(--hover-color));
  color: white;
  padding: var(--spacing-lg) 0;
  margin-bottom: var(--spacing-md);
}

.hero-section p {
  color: rgba(255, 255, 255, 0.8);
}

/* Navigation Styles */
.nav-link.active {
  color: var(--primary-color) !important;
  font-weight: 500;
}

/* Updated Footer Styles */
.footer-text {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 1rem;
  line-height: 1.6;
}

.footer-link {
  color: rgba(255, 255, 255, 0.9) !important;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.footer-link:hover {
  color: var(--primary-color) !important;
  text-decoration: none;
}

.footer-copyright {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 0.9rem;
}

/* Social Icons */
.social-icons {
  margin-top: var(--spacing-sm);
}

.social-icons a {
  font-size: 1.5rem;
  margin-right: var(--spacing-sm);
  color: var(--footer-text);
  transition: var(--transition-default);
}

.social-icons a:hover {
  color: var(--primary-color);
}

/* Blog Post Cards */
.blog-post-card {
  margin-bottom: var(--spacing-md);
}

.blog-post-card .card-img-top {
  height: 200px;
  object-fit: cover;
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
}

/* Button Styles */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: var(--transition-default);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
}

.btn-primary:hover {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
  transform: translateY(-2px);
}

/* Form Control Focus States */
.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Error Boundary Styles */
.error-boundary {
  padding: var(--spacing-md);
  margin: var(--spacing-md);
  background-color: #ff5252;
  color: white;
  border-radius: var(--border-radius-sm);
  text-align: center;
}

/* Accessibility */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Responsive Design */
@media (max-width: 1200px) {
  :root {
    --spacing-lg: 2.5rem;
  }
}

@media (max-width: 992px) {
  :root {
    --spacing-lg: 2rem;
  }
}

@media (max-width: 768px) {
  :root {
    --spacing-md: 1.5rem;
    --spacing-lg: 1.75rem;
  }

  .hero-section {
    padding: var(--spacing-md) 0;
  }
  
  .memory-game-card {
    height: 120px;
  }
  
  .social-icons {
    margin-top: var(--spacing-xs);
  }
  
  footer {
    padding: var(--spacing-md) 0;
  }
}

@media (max-width: 576px) {
  :root {
    --spacing-sm: 0.75rem;
    --spacing-md: 1.25rem;
  }

  .hero-section {
    padding: var(--spacing-sm) 0;
  }
  
  .contact-form {
    padding: var(--spacing-sm);
  }
  
  .blog-post-card .card-img-top {
    height: 150px;
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/* Print Styles */
@media print {
  body {
    background: white !important;
    color: black !important;
  }

  .hero-section {
    background: none !important;
    color: black !important;
    padding: var(--spacing-sm) 0;
  }
  
  .social-icons,
  .nav-link,
  .btn-primary {
    display: none !important;
  }

  a {
    text-decoration: underline;
  }

  a[href^="http"]:after {
    content: " (" attr(href) ")";
  }
}