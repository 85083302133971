.memory-game-card {
  cursor: pointer;
  height: 100px;
  perspective: 1000px;
  margin-bottom: 15px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.memory-game-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.card-front {
  background-color: #f8f9fa;
}

.card-back {
  background-color: #e3f2fd;
  transform: rotateY(180deg);
}

.memory-game-card:hover .card-inner {
  transform: scale(1.05) rotateY(0deg);
}

.memory-game-card.flipped:hover .card-inner {
  transform: scale(1.05) rotateY(180deg);
}
